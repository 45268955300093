import React from "react";

/**
 * Displays a system error page when DLA eligibility reports an error
 * @param {*} props 
 * @returns the page
 */
export default function SysError(props) {

    console.log("System Error Page");

    return (
        <div style={{ margin: "0 auto" }}>

            <h1>Something went wrong</h1>

            <div className="alert alert-danger" role="alert">

                <h2><i className="fa fa-times-circle" style={{ float: "left" }}></i></h2>
                We are aware of the problem and are currently working to restore services.

                <p>From here, you can select 'Cancel' or 'X' to close this browser - you will be returned to the opening screen of the Digital Licence app.</p>
                <p>You can then try again later by selecting 'Get Started' from the Digital Licence app.</p>

            </div>

        </div>
    );
}