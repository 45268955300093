import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "./Loading";
import { v4 as uuidv4 } from 'uuid';
import * as Constants from "../constants"

export function EligibilityCheck() {

    const { getAccessTokenSilently, getIdTokenClaims, isLoading } = useAuth0();
    const [isIP2CL2, setIsIP2CL2] = useState(null);
    const [isEligible, setIsEligible] = useState(null);
    const [isSystemError, setIsSystemError] = useState(false);
    const [eligibilityChecked, setEligibilityChecked] = useState(false);
    const axios = require('axios');
    const navigate = useNavigate();

    const search = useLocation().search;
    const error = new URLSearchParams(search).get("error");
    const error_description = new URLSearchParams(search).get("error_description");

    const checkEligibility = async () => {

        /*
            First, get access token. If this fails, user not logged in. Redirect
            to login page which in turn will redirect back to this page on successful
            login. 
            
            Check that the user has IP2CL2 in order to qualify
            otherwise display page saying their QDI does not meet requirements.

            If all good check whether they already registered the DLA from another
            device by checking existance of a dla_id in their ID token, if so
            they are already eligible so no need to check again.
        */

        try {
            const token = await getAccessTokenSilently();
            if (getIdTokenClaims && token) {

                getIdTokenClaims().then(claims => {
                    const auth0_userId = claims.sub;
                    const ipnValue = claims["http://oauth.tmr.qld.gov.au"].ipn;
                    const mfaAvailable = claims["http://oauth.tmr.qld.gov.au"].mfa_available;

                    if (Constants.DEBUG) {
                        console.log("User %o and has achieved %o, mfa_available is %o", auth0_userId, ipnValue, mfaAvailable);
                    }

                    if (!mfaAvailable || (!ipnValue || ipnValue !== "ip2")) {
                        // User does not have IP2 and/or not enrolled in MFA
                        if (Constants.DEBUG) { console.log("User does not have IP2CL2"); }
                        setIsIP2CL2(false);
                        return;

                    } else {
                        const dla_id = claims["http://oauth.tmr.qld.gov.au"].dla_id;
                        if (dla_id) {
                            if (Constants.DEBUG) { console.log("User has dla_id, eligible"); }
                            setIsEligible(true);
                            return;
                        }
                    }

                    if (!isEligible) {
                        // No dla_id, perform eligibility check...

                        var HttpCodes = { OK: 200, notFound: 404 }

                        const payload = { DLAUserAccount: { user_id: auth0_userId } };
                        const headers = {
                            'Authorization': 'Bearer ' + token,
                            'eip_client_id': `${process.env.react_app_eip_client_id}`,
                            'X-Correlation-ID': uuidv4()
                        };
                        const x_api_url = process.env.react_app_audience;

                        if (Constants.DEBUG) { console.log("Checking eligibility of user..."); }

                        axios
                            .post(x_api_url + "qdi/users/dla/eligibilitycheck", payload, { headers })
                            .then((response) => {
                                if (Constants.DEBUG) { console.log(response); }
                                if (response.status === HttpCodes.OK) {
                                    if (Constants.DEBUG) { console.log('User ' + auth0_userId + ' eligible'); }
                                    setIsEligible(true);
                                }
                            })
                            .catch((error) => {
                                const httpStatus = error.response.status;
                                const message = error.response.data.Results[0]?.ErrorResponse.Description;
                                if (Constants.DEBUG) { console.log('User ' + auth0_userId + ' ' + message + ', ' + httpStatus); }

                                if (httpStatus === HttpCodes.notFound) {
                                    if (message === 'Not eligible') {
                                        /*
                                            User has a QDI but no TMR product, display TMR product page * ON HOLD *
                 
                                            18/10/2022 As per conversation with Graeme, as go live will
                                            require a TMR product to sign up for a QDI this condition will never
                                            be met so instead of forwarding to TMR product page, display an error
                                        */
                                        //setRequiresTMRProduct(true);
                                        setIsSystemError(true);
                                    }
                                    else {
                                        /* "User not found in source system" */
                                        setIsSystemError(true);
                                    }
                                } else {
                                    /* Catch all */
                                    setIsSystemError(true);
                                }
                            });

                        setEligibilityChecked(true);
                    }

                }).catch(err => {
                    if (Constants.DEBUG) { console.log("Error retrieving users claims", err); }
                    setIsSystemError(true);
                })

            } else {
                /* Unable to get claims or access token */
                if (Constants.DEBUG) { console.log("Error retrieving users access token and/or claims"); }
                setIsSystemError(true);
            }

        } catch (error) {
            if (Constants.DEBUG) { console.log("User not authenticated. Redirecting to /login..."); }
            navigate("/login", { replace: true });
        }

    }

    /**
     * After page load, check eligibility
     */
    useEffect(() => {
        if (!isLoading && !eligibilityChecked) { // IDP-4978 Perform eligibility after page load.
            checkEligibility();
        }
    }, [isLoading]);

    /**
     * If eligible, call deep link. If still here after timeout display system error.
     */
    useEffect(() => {
        if (isEligible) {
            if (Constants.DEBUG) { console.log("User has passed eligibility. Callling deep link..."); }
            // /* Call deep link. Display error page if app not triggered */
            window.location.href = process.env.react_app_deep_link;
            setTimeout(function () {
                navigate("/SysError", { replace: true });
            }, 5500);
        }
    }, [isEligible, navigate]);

    /**
     * Users QDI is not IP2CL2 - This check is here incase bad actor
     * passed IP2:CL2 QSP to the DLA landing page
     */
    useEffect(() => {
        if (isIP2CL2 != null && !isIP2CL2) {
            if (Constants.DEBUG) { console.log("User does not have IP2:CL2"); }
            navigate("/TryAgain", { replace: true });
        }
    }, [isIP2CL2, navigate]);

    /**
     * Trigger when system error flag set true. Redirect to system error page.
     */
    useEffect(() => {
        if (isSystemError) {
            if (Constants.DEBUG) { console.log("Error: No IPCL, not eligible or other system error"); }
            navigate("/SysError", { replace: true });
        }
    }, [isSystemError, navigate]);

    /**
     * Error returned from QDI when calling the /EligibilityCheck
     */
    useEffect(() => {
        if (error) {
            if (Constants.DEBUG) { console.log("ERROR! - could not perform eligibility checks. Error from QDI.", error_description); }
            navigate("/SysError", { replace: true });
        }
    }, [error, navigate]);


    // Upcoming CICM work
    //
    // useEffect(() => {
    //     if (requiresTMRProduct) {
    //         navigate("/SelectTMRProduct", { replace: true });
    //     }
    // }, [requiresTMRProduct, navigate]);

    return (
        <>
            <h1>Queensland Digital Licence App</h1>
            <Loading />
        </>
    );

};

export default EligibilityCheck;