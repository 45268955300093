import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import * as Constants from "../constants";

export default function  ServiceUnavailable() {
    const [searchParams] = useSearchParams();
    const message = searchParams.get("message");

    return (
        <>
            <div style={{ margin: "0 auto" }}>

                <h1>Queensland Digital Identity</h1>

                <div className="alert alert-danger" role="alert">
                    <h2><span className="fa fa-times-circle"></span>Queensland Digital Identity is currently unavailable</h2>
                    {message ? <p>{message}</p> : <p>The Department of Transport and Main Roads apologises for any inconvenience.</p> }
                    <p>If you require immediate assistance please <a href={Constants.TROUBLESHOOTING_HELP_URL}>contact us</a></p>
                </div>

            </div>
        </>
    )
}