import React from "react";
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
// import { Container } from "reactstrap";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./views/Home";
import history from "./utils/history";

import { SignUp, Login } from "./components/LoginSignUp";
import QdiWelcome from "./components/QDI/QdiWelcome";
import TermsAndConditions from "./components/QDI/TermsAndConditions";

// import SelectTMRProduct from "./content/SelectTMRProduct";
// import DriversLicenceForm from "./components/DriversLicenceForm";
// import PhotoIdForm from "./components/PhotoIdForm";
// import MarineLicenceForm from "./components/MarineLicenceForm";

import EligibilityCheck from "./components/EligibilityCheck";
import Ineligible from "./content/Ineligible";
import IneligibleForPilot from "./content/IneligibleForPilot";
import TryAgain from "./content/TryAgain";
import SysError from "./content/SysError";
import SessionTimeout from "./content/SessionTimeout";
import PageNotFound from "./content/PageNotFound";
import Aside from "./components/Aside";
import SocialMedia from "./components/SocialMedia";
// import SectionNavigation from "./components/SectionNavigation";
import ServiceUnavailable from "./content/ServiceUnavailable";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

/**
 * The main application
 * @returns the app
 */
const App = () => {  
  return (
    <>      
      <Router history={history}>

        <Header />

        <div className="container-fluid qg-site-width">

          <div id="qg-content">
            <div id="qg-three-col" className="row wide">

              <div id="qg-primary-content" role="main">

                <Routes>
                  <Route path="/" element={<Home/>}/>
                  <Route path="*" element={<PageNotFound />} />
                  <Route path="/service-unavailable" element={<ServiceUnavailable />} />

                  <Route path='/signUp' element={<SignUp/>} />
                  <Route path='/login' element={<Login/>} />

                  {/* <Route path="/QdiWelcome" element={<QdiWelcome/>} />
                  <Route path="/TermsAndConditions" element={<TermsAndConditions/>} /> */}

                  {/* <Route path="/SelectTMRProduct" element={<SelectTMRProduct/>} />
                  <Route path="/DriversLicenceForm" element={<DriversLicenceForm/>} />
                  <Route path="/PhotoIdForm" element={<PhotoIdForm/>} />
                  <Route path="/MarineLicenceForm" element={<MarineLicenceForm/>} /> */}

                  <Route path="/EligibilityCheck" element={<EligibilityCheck/>} />
                  <Route path="/IneligibleForPilot" element={<IneligibleForPilot/>} />
                  <Route path="/Ineligible" element={<Ineligible/>} />
                  <Route path="/TryAgain" element={<TryAgain/>} />
                  <Route path="/SysError" element={<SysError/>} />
                  <Route path="/SessionTimeout" element={<SessionTimeout/>} />
                </Routes>

                {/* <SocialMedia /> */}

              </div>

              <Aside />

              {/* <SectionNavigation /> */}


            </div>
          </div>
        </div>
      </Router>
      <Footer />

    </>
  );
};

export default App;
