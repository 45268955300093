import React from "react";

/**
 * Displayed when the user is not eligible for the pilot.
 * 
 * @returns the page
 */
export default function IneligibleForPilot() {

    console.log("Not able to participate in the pilot");

    return (
        <div style={{ margin: "0 auto" }}>
            
            <h1>You are not eligible to participate in the pilot</h1>

            <div className="alert alert-danger" role="alert">

                <h2><i className="fa fa-times-circle" style={{ float: "left" }}></i></h2>
                You have successfully completed the Digital Licence app signup process.

                <p>
                    However, your home postcode means you are not currently eligible to participate
                    in the Digital Licence app trial.
                </p>
                <p>
                    Visit the <a href="https://www.qld.gov.au/transport/projects/digital-licence">Digital Licence app website</a> for more information
                    on the trial, and postcode eligibility.
                </p>
            </div>

        </div>
    );
}