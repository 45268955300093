import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { useAuth0 } from "@auth0/auth0-react";

const qdiHostDomain = process.env.react_app_qdi_host;
const reqestIpLevel = process.env.react_app_request_ip_level;
console.log('QDI host environment is', qdiHostDomain);

/**
 * Calls the QDI portal web application with the required IP level for the user to signup
  * 
 * Note - Due to auth0 double SSO problem when triggering signup/login via QDI we
 * now call auth0 direct to initiate login/signup. This also meant bringing across
 * the Terms And Conditions screen to faciliate the correct Signup sequence
 * 
 * @returns The page
 */
const SignUp = () => {
    // const { loginWithRedirect } = useAuth0();
    // loginWithRedirect({ screen_hint: "signup" })
    // return (<><DisplayGlif /></>)
    return (
        <>
            <LaunchQdi host={qdiHostDomain + "/signup?acr=" + reqestIpLevel}/>
        </>
    ); 
}

const Login = () => {
    // const { loginWithRedirect } = useAuth0();
    // loginWithRedirect();
    // return (<><DisplayGlif /></>);
    return (
        <>
            <LaunchQdi host={qdiHostDomain + "/login?acr=IP2:CL2"}/>
        </>
    )  
}

// /**
//  * Loads the QDI web portal
//  * @param {*} props target host of the QDI portal
//  * @returns Page displaying 'Please wait'
//  */
const LaunchQdi = (props) => {
    useEffect(() => {
        window.location.assign(props.host);
    });  
    return ( <><DisplayGlif/></>);
}

const DisplayGlif = () => {
    return (
        <>
            <h1>Queensland Digital Licence App</h1>
            <Loading />

        </>
    );
}

export { SignUp, Login }