import React from "react";
//import { Link } from "react-router-dom";

/**
 * Displayed when not eligible due to cancelling from entering TMR product after
 * directed to do so because they came to DLA as IP2CL2 but had not TMR product
 * 
 * @returns the page
 */
export default function Ineligible() {

    console.log("DLA eligibility not met page");

    return (

        <div style={{ margin: "0 auto" }} >

            <h1>You have not met the eligibility requirements for the Digital Licence app</h1>

            <div className="alert alert-danger" role="alert">

                <h2><i className="fa fa-times-circle" style={{ float: "left" }}></i></h2>
                Select 'Cancel' or 'X' to close this browser.

                <p>You will be returned to the opening
                    screen of the Digital Licence app. Visit the Digital Licence app website for more
                    information.</p>

            </div>

        </div>
    );
}