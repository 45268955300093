import React from "react";
import { Link } from "react-router-dom";
import * as Constants from "../constants"

/**
 * Displays a Try Again page when user is either;
 * - returend from QDI portal as a result of not completing the signup process
 * - Fails DLA eligibility (no return code sent) from within this app
 * 
 * Based on the property value the Try Again action will either be login or signup
 * No property means the call came from within this app so proceed to login
 * 
 * @param {*} props returnCode passed back from QDI portal
 * @returns the page
 */
export default function TryAgain(props) {

    if (Constants.DEBUG) { console.log("QDI eligibility TryAgain page, return code :" + props.retCode); }

    /**
     * Process the outcome returned from QDI. See IDP-1107
     */
    let action = "";
    switch(props.retCode) {
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
            action = "/login";
            break;
        case "9":
            action = "/";
            break;
        case "10":
            action = "/login";
            break;
        default:
            action = "/";
    }

    return (
        <div style={{ margin: "0 auto" }}>

            <h1>Your Queensland Digital Identity did not meet the requirements for the Digital Licence app</h1>

            <div className="alert alert-danger" role="alert">

                <h2><i className="fa fa-times-circle" style={{ float: "left" }}></i></h2>
                From here, you will need to do one of the following:

                <p>Select 'Try Again' to return, and continue creating your Queensland Digital Identity.</p>
                <p>Select 'Cancel' or 'X' to close the browser - you will be returned to the opening screen of the Digital Licence app.</p>
                <p>Visit the <a href={Constants.CONTACT_US_URL} target="_blank" rel="noopener">Queensland Digital Identity website</a> for more information</p>

            </div>

            <ol className="questions">
                <li className="footer">
                    <ul className="actions">
                        <li>
                            <Link id='tryAgain' to={action}>
                                <strong>
                                    <button type="button" className="qg-btn btn-primary">Try again</button>
                                </strong>
                            </Link>
                        </li>
                    </ul>
                </li>
            </ol>

        </div>
    );
}