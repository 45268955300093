import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import TryAgain from "./TryAgain";
import { properties } from "../QdiProperties";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import * as Constants from "../constants"
import { useNavigate } from "react-router-dom";
import awsconfig from "../aws-exports";
import { API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
/**
 * Displays the welcome page from where the user can choose to either login or signup
 * via the QDI portal.
 * 
 * If QSPs where received then this page will;
 * - Do eligibility check when successful IPCL reached
 * - Display TryAgain if signup process not completed and error code returned
 * 
 * @returns the page
 */
export const Welcome = () => {

    const search = useLocation().search;
    const acr = new URLSearchParams(search).get("acr");
    let returnCode = new URLSearchParams(search).get("returnCode");

    const { loginWithRedirect } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {        
        // Check DLA service availability
        async function getServiceStatus(){
            API.configure(awsconfig);

            try {
                let response = await API.get("DlaApi", `/service-status?service=DLA`, {
                    headers: {
                        "X-Request-Id": uuidv4(),
                    }
                });
            
                if(response?.[0].service_name === 'DLA' && response?.[0].service_status === 'UP') {
                    return;
                } else if(response?.[0].service_name === 'DLA' && response?.[0].service_status === 'DOWN') {
                    navigate("/service-unavailable?message=" + response?.[0].status_message, { replace: true });
                } else {
                    throw new Error("Unhandled response.")
                }
            } catch (err) {
                if (err.errors) {
                    console.log('Error received querying service status', err.errors[0].message);
                }
            } 
        }

        getServiceStatus();
    }, []);

    // useEffect(() => {
    if (acr != null || returnCode != null) {
        // Recognised QSP returned from QDI portal
        // Commented out since we have rewired signup to do directly to auth0
        // as opposed to QDI whilst we rebuild QdI in React. This results in
        // the redirect after signup/uplift going straight back to DLA instead
        // of java QDI. This is to get around the SSO issue betweem DLA and QDI.
        //
        // Note, because of this QDI can no longer presents the welcome screen and
        // Ts&Cs as well as being unable to record the refers url. This means if
        // the user quits during sign up, QDI is unable to send user back to DLA
        //
        if (acr != null) {
            // ACR value returned from QDI portal
            if (acr.toLowerCase().includes(process.env.react_app_required_ip_level)) {
                if (Constants.DEBUG) { console.log('Successful ACR value returned, call loginWithRedirect() to start eligibility check...'); }
                // Login with SSO, this will then redirect to EligibilityCheck page as per auth0 config
                loginWithRedirect();
                return (
                    <>
                        <h1>Queensland Digital Licence App</h1>  
                        <Loading />
                    </>
                );
            
            } else {
                if (Constants.DEBUG) { console.log('Non successful or invalid ACR value returned from QDI, ', acr); }
                // above log appears to be loaded twice on page load (browser console)-- uncommenting outer useEffect declarations should fix
                if (returnCode == null) {
                    returnCode = properties.invalidAcr;
                }
            }
        }

        if (returnCode != null) {
            // error returnCode returned from QDI portal
            if (Constants.DEBUG) { console.log('QDI return code, value ', returnCode); }
            if (+returnCode !== 99 && +returnCode !== 9) { 
            return (
                // User did not complete QDI signup process
                <TryAgain retCode={returnCode}/>
            );
            }
        }

    } else {
        if (Constants.DEBUG) { console.log('No recognised query string parameters detected.'); }
    }
    // }, []);

    return (
        <div>
            <h1>Queensland Digital Licence App</h1>

            <div style={{ margin: "0 auto" }}>

                {+returnCode === 99 && (
                    <div className="my-3 alert alert-danger" role="alert">
                        <h2><span className="fa fa-times-circle"></span>
                            Unauthorised access</h2>

                        <div>
                            <p>The page you attempted to access cannot be viewed without valid permissions.
                                <br /><br />
                                Your access may have been invalidated for some reason.</p>
                        </div>
                    </div>
                )}
                
                <p>In order to obtain your Digital Licence App you must have a Queensland Digital Identity (QDI).</p>
                <p>To sign up for a QDI you will need to provide some forms of identification that will be verified during the sign up process.</p>
                <p>If you already have a QDI please use the log in option and enter your QDI email and password.</p>

                <ol className="questions">
                    <li className="footer">
                        <ul className="actions">

                             <li>
                                <Link id="signup" to="/signup">
                                    <strong>
                                        <button id="signUpButton" type="button" className="qg-btn btn-primary">Sign-up</button>
                                    </strong>
                                </Link>
                            </li>

                            <li>
                                <Link id="login" to="/login">
                                    <strong>
                                        <button id="loginButton" type="button" className="qg-btn btn-outline-dark">Login</button>
                                    </strong>
                                </Link>
                            </li>

                        </ul>
                    </li>
                </ol>

            </div>

        </div>
    );

}

export default Welcome;